<template>
  <v-card
    class="d-flex flex-column"
    rounded="sm"
    flat
    :style="{
      color: color,
      backgroundColor: backgroundColor
    }"
    :href="link"
    @click.prevent="handleLink"
  >
    <v-img
      :src="src"
      height="201"
      max-height="201"
      :title="'logo' + proposal.descr"
      :alt="'Immagine' + proposal.descr"
      eager
    />
    <v-card-text
      class="d-flex justify-center align-center text-center text-uppercase font-weight-bold h-100"
      v-html="proposal.descr"
      :style="{
        color: color
      }"
    ></v-card-text>
    <v-card-text
      class="content pa-3"
      v-if="showContent && proposal.content"
      v-html="proposal.content"
    ></v-card-text>
    <v-spacer />
  </v-card>
</template>

<script>
import banner from "~/mixins/banner";

export default {
  name: "ProposalCard",
  props: ["proposal", "showContent"],
  mixins: [banner],
  computed: {
    color() {
      return global.vm.lodash.get(
        this.proposal,
        "metaData.category_proposal_type.COLOR"
      );
    },
    backgroundColor() {
      return global.vm.lodash.get(
        this.proposal,
        "metaData.category_proposal_type.BG_COLOR"
      );
    },
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (this.$vuetify.breakpoint.xs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return this._.get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
