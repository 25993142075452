<template>
  <div>
    <div v-html="content" @click="clicked"></div>
    <v-container class="px-0 px-sm-3 my-16">
      <ProposalCustomGrid
        :title="$t('headerBanner.title')"
        :proposals="headerBanner"
        :showProposalContent="true"
      />
    </v-container>
  </div>
</template>
<script>
import ProposalCustomGrid from "@/components/proposal/ProposalCustomGrid";
import category from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "PageAssortment",
  components: { ProposalCustomGrid },
  mixins: [category, clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner"
    }),
    content() {
      return this._.get(this.page, "content.rendered");
    }
  },
  mounted() {
    this.setCategory({ slug: "home-new" });
  }
};
</script>
