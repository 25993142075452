<template>
  <v-container class="mx-auto" v-intersect.once="handleView">
    <div class="d-flex justify-center">
      <h2 v-html="title" v-if="title"></h2>
    </div>
    <v-row>
      <v-col
        v-for="proposal in proposals"
        :key="proposal.id"
        class="d-flex justify-center"
        cols="6"
        sm="4"
        lg="3"
      >
        <ProposalCard :proposal="proposal" :showContent="showProposalContent" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProposalCard from "./ProposalCard.vue";

import proposalMixin from "~/mixins/proposal";
export default {
  name: "ProposalCustomGrid",
  props: {
    showProposalContent: { type: Boolean, required: false, default: false }
  },
  mixins: [proposalMixin],
  components: { ProposalCard },
  data() {
    return {};
  }
};
</script>
