<template>
  <v-card
    color="primary lighten-1 py-4"
    elevation="0"
    class="pa-2 d-none d-md-flex flex-column align-center white--text"
    height="220px"
  >
    <h3 class="text-center mt-8">
      <strong>{{ $t("leftColumnCards.beCustomer.title") }}</strong>
    </h3>
    <v-btn
      dense
      depressed
      text
      :to="'/registration-user'"
      class="white--text primary"
    >
      {{ $t("leftColumnCards.beCustomer.button") }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "BeCustomerCard"
};
</script>
