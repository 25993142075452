<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="3"
        order="last"
        order-md="first"
        class="pa-0 pa-md-3"
      >
        <FaqCard class="mb-0 mb-md-5" :small="true" />
        <SupportCard class="mb-0 mb-md-5" />
        <BeCustomerCard
          class="mb-0 mb-md-5"
          v-if="!isAuthenticated && showBeCustomerCard"
        />
      </v-col>
      <v-col cols="12" md="9">
        <div v-html="content" @click="clicked"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import SupportCard from "@/components/cards/SupportCard";
import FaqCard from "@/components/cards/FaqCard";
import BeCustomerCard from "@/components/cards/BeCustomerCard";
import { mapGetters } from "vuex";
export default {
  name: "PageTwoColumn",
  mixins: [clickHandler],
  components: {
    SupportCard,
    FaqCard,
    BeCustomerCard
  },
  props: { page: { type: Object, required: true } },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    content() {
      return this._.get(this.page, "content.rendered");
    },
    showBeCustomerCard() {
      return this.page.slug != "codice-etico";
    }
  }
};
</script>
