<template>
  <div v-html="content" @click="clicked"></div>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Static",
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return this._.get(this.page, "content.rendered");
    }
  }
};
</script>
