<template>
  <v-card
    color="primary lighten-1"
    elevation="0"
    class="pa-2 d-flex flex-column align-center white--text"
    height="220px"
  >
    <h3 class="title text-center mt-4">
      <strong>{{ $t("leftColumnCards.faq.title") }}</strong>
    </h3>
    <v-btn depressed color="primary" :to="'/faq'" class="white">
      {{ $t("leftColumnCards.faq.button") }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "FaqCard",
  // forza l'immagine di background utilizzando quella piccola
  props: ["small"]
};
</script>
