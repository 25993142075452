<template>
  <v-row>
    <v-col v-for="cardName in cardsName" :key="cardName" cols="12" md="6">
      <FooterCard :cardName="cardName" />
    </v-col>
  </v-row>
</template>

<script>
import FooterCard from "./FooterCard.vue";

export default {
  name: "FooterCardsGrid",
  props: {
    cardsName: { type: Array, required: true }
  },
  components: { FooterCard }
};
</script>
