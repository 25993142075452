<template>
  <v-container @click="clicked" class="form-servizio-clienti mb-6">
    <v-form ref="formRef" v-if="form != null" v-model="valid">
      <v-row>
        <v-col cols="12">
          <h3 class="form-title">
            Inserisci i tuoi dati e invia la tua richiesta:
          </h3>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['19_1'].label"
            v-model="form.fields['19_1'].value"
            dense
            outlined
            :rules="[requiredRule()]"
            required
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['20_1'].label"
            v-model="form.fields['20_1'].value"
            outlined
            dense
            :rules="[requiredRule()]"
            required
          >
          </v-text-field
        ></v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['21_1'].label"
            v-model="form.fields['21_1'].value"
            outlined
            dense
            :rules="emailRules"
            required
          >
          </v-text-field
        ></v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="form.fields['22_1'].label"
            v-model="form.fields['22_1'].value"
            outlined
            dense
            :rules="mobilePhoneRules"
          >
          </v-text-field
        ></v-col>
        <v-col cols="12">
          <h3>Come possiamo aiutarti?</h3>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :label="form.fields['23_1'].label"
            v-model="form.fields['23_1'].value"
            outlined
          >
          </v-textarea>
        </v-col>
        <div class="legal-agreement">
          <div v-if="showPrivacy">
            <v-col cols="12" v-if="!warehouseEmail">
              <v-checkbox
                :label="form.fields['25_1'].label"
                v-model="form.fields['25_1'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="!warehouseEmail">
              <v-checkbox
                :label="form.fields['26_1'].label"
                v-model="form.fields['26_1'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
          </div>
          <div v-else>
            <v-col cols="12">
              <div v-html="form.fields['40_1'].desc_text"></div>
              <v-checkbox
                :label="form.fields['40_1'].label"
                v-model="form.fields['40_1'].value"
                outlined
              >
              </v-checkbox>
            </v-col>
          </div>
        </div>
        <v-col cols="12" class="send-data-btn">
          <v-btn
            color="primary"
            depressed
            :disabled="!valid || (!showPrivacy && !form.fields['40_1'].value)"
            v-model="form.fields['24_1'].value"
            @click.stop.prevent="handleSubmit"
          >
            {{ form.fields["24_1"].label }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CMService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";
import { mapGetters } from "vuex";
import {
  requiredValue,
  isEmail,
  isMobilePhone
} from "@/validator/validationRules";

export default {
  data() {
    return {
      // warehouseEmail: null,
      form: null,
      valid: true,
      requiredRule: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [isMobilePhone()]
    };
  },
  props: {
    warehouseEmail: { type: String, default: null },
    showPrivacy: { type: Boolean, default: true }
  },
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    async fetchForm(formId) {
      let _this = this;
      _this.form = await CMService.getForm(formId);
      if (_this.isAuthenticated) {
        _this.form.fields["19_1"].value = _this.user.firstName;
        _this.form.fields["20_1"].value = _this.user.lastName;
        _this.form.fields["21_1"].value = _this.user.email;
        _this.form.fields["22_1"].value =
          _this.user.billingAddress.referenceMobileNumber;
      }
      if (_this.warehouseEmail) {
        _this.form.fields["37_1"].value = _this.warehouseEmail;
      }
    },
    handleSubmit() {
      if (this.$refs.formRef.validate()) {
        CMService.sendForm(this.form);
      }
    }
  },
  mounted() {
    this.fetchForm("5");
  }
};
</script>
