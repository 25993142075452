<template>
  <div class="faq">
    <v-container>
      <div v-html="content" @click="clicked"></div>
    </v-container>
    <v-container class="relative">
      <v-row>
        <v-col cols="0" md="3" v-if="!$vuetify.breakpoint.mobile">
          <h2 class="faq-title">FILTRA LE FAQ PER:</h2>
          <FaqCategory />
          <v-divider class="my-5"></v-divider>
          <SupportCard />
        </v-col>
        <v-col cols="12" md="9" class="position-relative">
          <div v-if="faqList != null">
            <v-expansion-panels class="faq-list" accordion flat>
              <v-expansion-panel
                v-for="item in faqList"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header class="secondary lighten-1">
                  <h3 v-html="item.title.rendered"></h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.content.rendered"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="faq-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  mdi-close
                </v-icon>
                <v-icon v-else>
                  mdi-tune-variant
                </v-icon>
              </v-btn>
            </template>
            <v-card><FaqCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FaqCategory from "@/components/wordpress/FaqCategory";
import SupportCard from "@/components/cards/SupportCard";
import CMSService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";

export default {
  name: "PageFaq",
  components: { FaqCategory, SupportCard },

  data() {
    return {
      faqList: null,
      fab: false
    };
  },
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return this._.get(this.page, "content.rendered");
    }
  },
  mounted() {
    this.fetchFaqList(this.$route.params.categoryName);
  },
  methods: {
    async fetchFaqList(categoryName) {
      let res = await CMSService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[categoria_faq]",
          filterValue: categoryName
        }
      ]);
      this.faqList = res.data;
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchFaqList(categoryName);
    }
  }
};
</script>
