<template>
  <div class="static-page" :class="page.slug">
    <page-title class="hidden-xs-only" :page="page" />
    <Breadcrumb :items="breadcrumb" />
    <component :is="templateName" :key="key" :page="page"></component>
    <ImgSlider
      v-if="this.page && this.page.slider_img"
      :imgs="this.page.slider_img"
    />

    <v-container>
      <FooterCardsGrid
        v-if="
          this.page &&
            (this.page.slug == 'diventa-cliente' ||
              this.page.slug == 'assortimento')
        "
        :cardsName="['serviziSpesa', 'storeLocator']"
      />
    </v-container>
    <BeCustomerCardHorizontal
      card="registrationHome"
      v-if="
        !isAuthenticated && this.page && this.page.slug != 'diventa-cliente'
      "
    />
    <FaqBar class="mt-5" v-else-if="this.page && this.page.banner_faq == '1'" />
    <NewsletterHorizontal />
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import FaqBar from "@/components/registration/FaqBar.vue";
import Static from "@/components/wordpress/Static.vue";
import PageTwoColumn from "@/components/wordpress/PageTwoColumn.vue";
import PageFaq from "@/components/wordpress/PageFaq.vue";
import PageContacts from "@/components/wordpress/PageContacts.vue";
import PageProductRecall from "@/components/wordpress/PageProductRecall.vue";
import PageAssortment from "@/components/wordpress/PageAssortment.vue";
import ImgSlider from "@/components/wordpress/ImgSlider.vue";
import FooterCardsGrid from "@/components/cards/FooterCardsGrid.vue";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import page from "~/mixins/page";
import { mapGetters } from "vuex";

export default {
  created() {
    this.fetchPage(this.$route.params.pageName);
  },
  mixins: [page],
  components: {
    Static,

    Breadcrumb,
    PageTwoColumn,
    ImgSlider,
    PageTitle,
    FaqBar,
    PageFaq,
    PageContacts,
    PageProductRecall,
    PageAssortment,
    FooterCardsGrid,
    BeCustomerCardHorizontal,
    NewsletterHorizontal
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    templateName() {
      if (this.page.template) {
        return this._.trimEnd(this.page.template, ".php");
      } else {
        return "Static";
      }
    }
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.fetchPage(pageName);
    }
  }
};
</script>
