<template>
  <v-card rounded="4" elevation="0" class="ma-4 primary">
    <v-card-title>
      {{ $t(`footerCards.${cardName}.title`) }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t(`footerCards.${cardName}.subtitle`) }}
    </v-card-subtitle>
    <v-img
      :src="$t(`footerCards.${cardName}.imgPath`)"
      :alt="'Immagine ' + $t(`footerCards.${cardName}.title`)"
      height="245"
    >
      <v-card-actions class="d-flex justify-center">
        <v-btn
          depressed
          class="primary"
          :to="$t(`footerCards.${cardName}.link`)"
        >
          {{ $t(`footerCards.${cardName}.button`) }}
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "FooterCard",
  props: {
    cardName: { type: String, required: true }
  }
};
</script>
